<template>
  <div class="login-container">
    <!-- 左侧区域 -->
    <div class="login-left-box">
      <img src="@/assets/imgs/logo.png" />
    </div>

    <!-- 右侧区域 -->
    <div class="login-right-box">
      <!-- 表单区域 -->
      <div class="form-box">
        <el-form
          :model="form"
          :rules="formRules"
          ref="formRef"
        >
          <!-- 账号 -->
          <el-form-item
            prop="account"
            class="account-item"
          >
            <el-input
              v-model.trim="form.account"
              prefix-icon="iconfont icon-zhanghao"
              :placeholder="$t('word1002')"
            >
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item
            prop="password"
            class="password-item"
          >
            <el-input
              v-model.trim="form.password"
              prefix-icon="iconfont icon-mima"
              :placeholder="$t('word1003')"
              show-password
            >
            </el-input>
          </el-form-item>
          <!-- 忘记密码 -->
          <el-form-item class="forget-password-item">
            <span
              class="forget-password"
              @click="$router.push({ name: 'forgetPassword' })"
            >{{ $t('word1004') }}</span>
          </el-form-item>
          <!-- 登录/注册 -->
          <el-form-item class="btn-item">
            <el-button
              class="btn-box"
              @click="loginIn"
            >{{ $t('word1005') }}</el-button>
            <!--      <el-button
              class="btn-box"
              @click="goRegister"
            >{{ $t('word1006') }}</el-button>-->
          </el-form-item>
        </el-form>
      </div>

      <!-- 底部区域、用户协议/个人隐私/关于我们 -->
      <div class="bottom-box">
        <span>{{ $t('word1007') }}</span>
        <span>{{ $t('word1008') }}</span>
        <span>{{ $t('word1009') }}</span>
      </div>

      <!-- 语言切换 -->
      <div class="language-box">
        <el-select
          v-model="lang.value"
          popper-class="public-select-dropdown"
          @change="langChange"
        >
          <el-option
            v-for="item in lang.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'
import { loginIn } from '@/api/api.js'
import { getChamberLanguage } from '@/api/api.js'
import { setCookie } from '@/js/tool.js'

export default {
  name: 'Login',
  mixins: [mixin],
  data() {
    return {
      // 表单对象
      form: {
        account: '',
        password: ''
      },
      // 表单校验对象
      formRules: {
        account: [{ required: true, message: this.$t('word1010'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('word1011'), trigger: 'blur' }]
      },

      // 语言数据
      lang: {
        options: [
          {
            value: 'zh-CN',
            label: '简体中文'
          },
          {
            value: 'en-US',
            label: 'English'
          },
          {
            value: 'bahasa-Indonesia',
            label: 'Bahasa Indonesia'
          }
        ],
        value: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'
      }
    }
  },

  beforeCreate() {
    if (sessionStorage.getItem('changeChange') != 'yes') {
      sessionStorage.setItem('locale', 'en-US')
    }
    sessionStorage.setItem('changeChange', 'no')
  },

  methods: {
    // 登录
    loginIn() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const { account, password } = this.form
          const params = {
            mobile: account,
            password,
            osType: '2',
            clientTags: this.getBrowser('v'),
            mac: this.getBrowser(),
            auroraId: ''
          }
          loginIn(params).then(res => {
            var _self = this
            if (res.code === 503 && res.msg === 'TheAccountHasBeenFrozen') {
              // 该账号已被冻结
              this.$message.error({ message: this.$t('word1012'), duration: 2000 })
            } else if (res.code === 504 && res.msg === 'accountDisabled') {
              // 该账号已被禁止
              this.$message.error({ message: this.$t('word1033'), duration: 2000 })
            } else {
              if (res.data.id === '0') return this.$message.error({ message: this.$t('word1013'), duration: 2000 })
              if (res.data.enable == 1) return this.$message.error({ message: this.$t('word1013'), duration: 2000 })
              this.$message.success({ message: this.$t('word1014'), duration: 1500 })
              setCookie('tenantCode', res.data.tenantCode)
              getChamberLanguage({
                tenantCode: res.data.tenantCode
              }).then(res3 => {
                var tenantAthenaChamberDTO3 = res3.data
                if (tenantAthenaChamberDTO3) {
                  sessionStorage.originalLanguage = tenantAthenaChamberDTO3.language
                  sessionStorage.locale = sessionStorage.originalLanguage
                  _self.saveUserInfo(res.data)
                  window.location.href = 'https://firm.attorneyathena.com/web/#/home'
                  //window.location.href = 'http://localhost:8088/#/home'
                }
              })
            }
          })
        } else {
          return false
        }
      })
    },

    // 跳转到注册页面
    goRegister() {
      // 获取地址栏参数值
      const url = window.location.href // 获取当前url
      const endUrl = url.split('#')[1] // 获取 #/ 之后的字符串
      const cs = endUrl.split('?') // 判断地址栏是否有参数
      if (cs.length > 1) {
        // 有参数
        const par = cs[1].split('=')[1] // 获取 = 之后的值
        this.$router.push({ name: 'register', query: { firmId: par } })
      } else {
        // 没参数
        this.$router.push({ name: 'register', query: { firmId: '100001' } })
      }
    },

    // 语言切换
    langChange(value) {
      const lang = value === 'zh-CN' ? 'zh_cn' : value === 'en-US' ? 'en' : value
      this.setCookie('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE', lang)
      sessionStorage.setItem('locale', value)
      sessionStorage.setItem('changeChange', 'yes')
      window.location.reload() // 刷新页面
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  height: 100%;

  // 左侧区域
  .login-left-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    background-color: #1c252c;
  }

  // 右侧区域
  .login-right-box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url('../assets/imgs/login_right_img.png') center center no-repeat;
    background-size: 100% 100%;

    // 表单区域
    .form-box {
      width: 400px;

      // 重写 elementUI 自带样式
      ::v-deep .el-form {
        .account-item {
          margin-bottom: 40px;
        }
        .password-item {
          margin-bottom: 10px;
        }
        .forget-password-item {
          margin-bottom: 30px;
          .el-form-item__content {
            line-height: 30px;
          }
          .forget-password {
            padding: 5px;
            color: #a7a5a6;
            cursor: pointer;
          }
        }
        .btn-item {
          .el-form-item__content {
            text-align: center;
          }
          .btn-box {
            background-color: #3f310c;
            margin: 0 15px;
            padding: 10px 40px;
            color: #ffffff;
            font-size: 18px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .el-form-item__content {
          line-height: 50px;
        }
        .el-input__prefix {
          color: #ffffff;
          left: 15px;
          .iconfont {
            font-size: 25px;
          }
          .el-input__icon {
            line-height: 50px;
          }
        }
        .el-input__inner {
          height: 50px;
          line-height: 50px;
          background-color: #77683f;
          padding-left: 50px;
          color: #ffffff;
          font-size: 18px;
        }
        .el-input__inner::placeholder {
          color: #ffffff;
          font-size: 18px;
        }
      }
    }

    // 底部区域
    .bottom-box {
      position: absolute;
      bottom: 20px;
      height: 40px;
      line-height: 40px;
      span {
        padding: 5px 10px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    // 语言切换
    .language-box {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 160px;
    }
  }
}
</style>